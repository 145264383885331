var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseLayout', {
    staticClass: "px-0",
    attrs: {
      "display-tabs": _vm.isFilterOpen
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-icon', {
          staticClass: "mr-2",
          on: {
            "click": _vm.dashboardLink
          }
        }, [_vm._v("mdi-arrow-left")]), _vm._v(" Forhåndsgodkjente søkere AOF kurs ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_vm.selectedRows.length > 0 ? _c('v-btn', {
          staticClass: "ma-2",
          attrs: {
            "outlined": "",
            "data-cy": "markAsReadBtn"
          }
        }, [_vm._v(" Marker som sett ")]) : _vm._e(), _c('v-btn', {
          attrs: {
            "color": "white",
            "outlined": ""
          },
          on: {
            "click": function click($event) {
              _vm.isFilterOpen = !_vm.isFilterOpen;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-filter-variant")]), _vm._v(" Filter ")], 1)];
      },
      proxy: true
    }, {
      key: "tabs",
      fn: function fn() {
        return [!_vm.currentGroupBy ? _c('v-btn', {
          attrs: {
            "outlined": ""
          },
          on: {
            "click": function click($event) {
              _vm.currentGroupBy = 'courseName';
            }
          }
        }, [_vm._v(" Grupper etter kurs ")]) : _vm._e(), _vm.currentGroupBy ? _c('v-btn', {
          attrs: {
            "outlined": ""
          },
          on: {
            "click": function click($event) {
              _vm.currentGroupBy = null;
            }
          }
        }, [_vm._v(" Fjern gruppering ")]) : _vm._e()];
      },
      proxy: true
    }])
  }, [[_c('BaseTableFiltered', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.preApprovedParticipants,
      "group-by": _vm.currentGroupBy,
      "item-key": "id"
    },
    scopedSlots: _vm._u([{
      key: "item.startDate",
      fn: function fn(_ref) {
        var value = _ref.value;
        return [_vm._v(" " + _vm._s(value ? _vm.formatLocalizedDateP(value) : "-") + " ")];
      }
    }, {
      key: "item.applicationSentDate",
      fn: function fn(_ref2) {
        var value = _ref2.value;
        return [_vm._v(" " + _vm._s(value ? _vm.formatLocalizedDateP(value) : "-") + " ")];
      }
    }, {
      key: "item.enrollmentDeadline",
      fn: function fn(_ref3) {
        var value = _ref3.value;
        return [_vm._v(" " + _vm._s(value ? _vm.formatLocalizedDateP(value) : "-") + " ")];
      }
    }, {
      key: "item.appliedViaForm",
      fn: function fn(_ref4) {
        var value = _ref4.value;
        return [_vm._v(" " + _vm._s(value ? "Søkt gjennom web" : "Lagt til av kursadmin") + " ")];
      }
    }, {
      key: "actions",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref6) {
              var on = _ref6.on,
                  attrs = _ref6.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": "",
                  "color": "primary",
                  "to": _vm.getParticipantsLink(item.courseId)
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-open-in-new")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Gå til deltaker")])])];
      }
    }], null, true),
    model: {
      value: _vm.selectedRows,
      callback: function callback($$v) {
        _vm.selectedRows = $$v;
      },
      expression: "selectedRows"
    }
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }